import { Ability } from '@casl/ability';
import { Conditions, PossibleAbilities } from 'Can';
import PublicLayout from 'layouts/PublicLayout';
import Loading from 'pages/shared/Loading';
import { UseQueryResult } from 'react-query';
import { GymRetrieve, User } from 'types';

import { getAthleteRouteMap } from './athlete';
import { getGymStaffRouteMap } from './gym-staff';
import { getKiloStaffRouteMap } from './kilo-staff';
import { getKioskRouteMap } from './kiosk';
import { getOwnerRouteMap } from './owner';
import { getSalesPortalRouteMap } from './sales-portal';
import { IRouteMap } from './types';
import { getUnauthenticatedRouteMap } from './unauthenticated';

export const loadingRouteMap: IRouteMap = {
  layout: PublicLayout,
  routes: [
    {
      key: 'loading',
      component: Loading,
      path: '*',
    },
  ],
  navLinks: [],
};

export const getRouteMapList = (
  user: User | undefined,
  isLoading: boolean | undefined,
  isAuthenticated: boolean | undefined,
  isKiosk: boolean,
  gymHook:
    | UseQueryResult<{
        data: GymRetrieve;
      }>
    | undefined,
  ability: Ability<PossibleAbilities, Conditions>,
  flowsFlag: boolean | undefined,
  isDesktop: boolean,
): IRouteMap[] => {
  if (isLoading) return [loadingRouteMap];
  if (!user || !isAuthenticated) return [getUnauthenticatedRouteMap(), getSalesPortalRouteMap()];
  if (isKiosk) return [getKioskRouteMap()];
  if (user.isKiloStaff) return [getKiloStaffRouteMap(), getSalesPortalRouteMap()];
  if (user.isOwner) return [getOwnerRouteMap(gymHook, flowsFlag, isDesktop), getSalesPortalRouteMap()];
  if (user.isGymStaff)
    return [getGymStaffRouteMap(user, ability, gymHook, flowsFlag, isDesktop), getSalesPortalRouteMap()];
  if (user.isAthlete) return [getAthleteRouteMap(), getSalesPortalRouteMap()];
  return [getUnauthenticatedRouteMap(), getSalesPortalRouteMap()];
};
