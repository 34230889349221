import React from 'react';

import {
  IconCalendar,
  IconUserCircle,
  IconArchive,
  IconFileInvoice,
  IconSettings,
} from '@tabler/icons-react';
import OwnerMobileLayout from 'layouts/OwnerMobileLayout';
import PrivateLayout from 'layouts/PrivateLayout';
import Loading from 'pages/shared/Loading';
import { UseQueryResult } from 'react-query';
import { Navigate } from 'react-router-dom';
import { GymRetrieve } from 'types';

import { IRouteMap } from './types';

export const ownerRouteMap: IRouteMap = {
  layout: PrivateLayout,
  routes: [
    {
      key: 'dashboard',
      component: React.lazy(() => import('pages/private/owner/Dashboard')),
      path: '/',
    },
    {
      key: 'arm',
      component: React.lazy(() => import('pages/private/owner/reports/ArmReport')),
      path: '/reports/arm/:type',
    },
    {
      key: 'total-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/TotalRevenue')),
      path: '/reports/total-revenue',
    },
    {
      key: 'upcoming-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/UpcomingRevenue')),
      path: '/reports/upcoming-revenue',
    },
    {
      key: 'pending-payments',
      component: React.lazy(() => import('pages/private/owner/reports/PendingPayments')),
      path: '/reports/pending-payments',
    },
    {
      key: 'past-due-payments',
      component: React.lazy(() => import('pages/private/owner/reports/PastDuePayments')),
      path: '/reports/past-due-payments',
    },
    {
      key: 'total-members',
      component: React.lazy(() => import('pages/private/owner/reports/TotalMembers')),
      path: '/reports/total-members',
    },
    {
      key: 'paying-members',
      component: React.lazy(() => import('pages/private/owner/reports/PayingMembers')),
      path: '/reports/paying-members',
    },
    {
      key: 'new-members',
      component: React.lazy(() => import('pages/private/owner/reports/NewMembers')),
      path: '/reports/new-members',
    },
    {
      key: 'cancelled-members',
      component: React.lazy(() => import('pages/private/owner/reports/CancelledMembers')),
      path: '/reports/cancelled-members',
    },
    {
      key: 'reactivated-members',
      component: React.lazy(() => import('pages/private/owner/reports/ReactivatedMembers')),
      path: '/reports/reactivated-members',
    },
    {
      key: 'cancelled-trial-members',
      component: React.lazy(() => import('pages/private/owner/reports/CancelledTrialMembers')),
      path: '/reports/cancelled-trial-members',
    },
    {
      key: 'missing-members',
      component: React.lazy(() => import('pages/private/owner/reports/MissingMembers')),
      path: '/reports/missing-members',
    },
    {
      key: 'trial-members',
      component: React.lazy(() => import('pages/private/owner/reports/TrialMembers')),
      path: '/reports/trial-members',
    },
    {
      key: 'athletes-on-hold',
      component: React.lazy(() => import('pages/private/owner/reports/AthletesOnHold')),
      path: '/reports/athletes-on-hold',
    },
    {
      key: 'athletes-at-risk',
      component: React.lazy(() => import('pages/private/owner/reports/AthletesAtRisk')),
      path: '/reports/athletes-at-risk',
    },
    {
      key: 'athlete-attendance',
      component: React.lazy(() => import('pages/private/owner/reports/AthleteAttendance')),
      path: '/reports/athlete-attendance',
    },
    {
      key: 'athlete-birthdays',
      component: React.lazy(() => import('pages/private/owner/reports/AthleteBirthdays')),
      path: '/reports/athlete-birthdays',
    },
    {
      key: 'package-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/PackageRevenue')),
      path: '/reports/package-revenue',
    },
    {
      key: 'package-category-revenue',
      component: React.lazy(() => import('pages/private/owner/reports/PackageCategoryRevenue')),
      path: '/reports/package-category-revenue',
    },
    {
      key: 'kiosk',
      component: React.lazy(() => import('pages/private/owner/Kiosk')),
      path: '/kiosk',
    },
    {
      key: 'scheduling',
      component: React.lazy(() => import('pages/private/owner/Scheduling')),
      path: '/calendar/scheduling',
    },
    {
      key: 'programming',
      component: React.lazy(() => import('pages/private/owner/Program')),
      path: '/calendar/programming',
    },
    {
      key: 'packages',
      component: React.lazy(() => import('pages/private/owner/Package')),
      path: '/products/packages',
    },
    {
      key: 'retail',
      component: React.lazy(() => import('pages/private/owner/Retail')),
      path: '/products/retail',
    },
    {
      key: 'fees-and-misc',
      component: React.lazy(() => import('pages/private/owner/FeesAndMiscProducts')),
      path: '/products/fees-and-misc-products',
    },
    {
      key: 'tax-rates',
      component: React.lazy(() => import('pages/private/owner/TaxRates')),
      path: '/products/tax-rates',
    },
    {
      key: 'sales-portal',
      component: React.lazy(() => import('pages/private/owner/SalesPortalConfiguration')),
      path: '/products/sales-portal',
    },
    {
      key: 'athletes',
      component: React.lazy(() => import('pages/private/owner/Athletes')),
      path: '/users/athletes',
    },
    {
      key: 'athlete-detail',
      component: React.lazy(() => import('pages/private/owner/AthleteDetail')),
      path: '/users/athletes/:userId/:activeTab?',
    },
    {
      key: 'staff',
      component: React.lazy(() => import('pages/private/owner/Staff')),
      path: '/users/staff',
    },
    {
      key: 'classes',
      component: React.lazy(() => import('pages/private/owner/ClassType')),
      path: '/events/classes',
    },
    {
      key: 'appointments',
      component: React.lazy(() => import('pages/private/owner/AppointmentType')),
      path: '/events/appointments',
    },
    {
      key: 'location',
      component: React.lazy(() => import('pages/private/owner/Location')),
      path: '/events/location',
    },
    {
      key: 'issued-documents',
      component: React.lazy(() => import('pages/private/owner/IssuedDocuments')),
      path: '/documents/issued',
    },
    {
      key: 'document-templates',
      component: React.lazy(() => import('pages/private/owner/DocumentTemplates')),
      path: '/documents/templates',
    },
    {
      key: 'invoices',
      component: React.lazy(() => import('pages/private/owner/Invoice')),
      path: '/invoices',
    },
    {
      key: 'payroll-report',
      component: React.lazy(() => import('pages/private/owner/PayrollReport')),
      path: '/payroll/payroll-report',
    },
    {
      key: 'pay-rates',
      component: React.lazy(() => import('pages/private/owner/PayRates')),
      path: '/payroll/pay-rates',
    },
    {
      key: 'assign-rates',
      component: React.lazy(() => import('pages/private/owner/AssignRates')),
      path: '/payroll/assign-rates',
    },
    {
      key: 'gym-settings',
      component: React.lazy(() => import('pages/private/owner/GymSettings')),
      path: '/gym-settings',
    },
    {
      key: 'log-work',
      component: React.lazy(() => import('pages/private/owner/LogWork')),
      path: '/log-work',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    { key: 'dashboard', label: 'Dashboard', route: '/' },
    { key: 'kiosk', label: 'Kiosk', route: '/kiosk' },
    {
      key: 'calendar',
      label: 'Calendar',
      route: '/calendar',
      subLinks: [
        { key: 'scheduling', label: 'Scheduling', route: '/calendar/scheduling' },
        { key: 'programming', label: 'Programming', route: '/calendar/programming' },
      ],
    },
    {
      key: 'products',
      label: 'Products',
      route: '/products',
      subLinks: [
        { key: 'packages', label: 'Packages', route: '/products/packages' },
        { key: 'retail', label: 'Retail', route: '/products/retail' },
        {
          key: 'fees-and-misc',
          label: 'Fees & Misc Products',
          route: '/products/fees-and-misc-products',
        },
        { key: 'tax-rates', label: 'Tax Rates', route: '/products/tax-rates' },
        {
          key: 'sales-portal',
          label: 'Sales Portal',
          route: '/products/sales-portal',
        },
      ],
    },
    {
      key: 'users',
      label: 'Users',
      route: '/users',
      subLinks: [
        { key: 'athletes', label: 'Athletes', route: '/users/athletes' },
        { key: 'staff', label: 'Staff', route: '/users/staff' },
      ],
    },
    {
      key: 'events',
      label: 'Events',
      route: '/events',
      subLinks: [
        { key: 'classes', label: 'Classes', route: '/events/classes' },
        { key: 'appointments', label: 'Appointments', route: '/events/appointments' },
        { key: 'location', label: 'Locations', route: '/events/location' },
      ],
    },
    {
      key: 'documents',
      label: 'Documents',
      route: '/documents',
      subLinks: [
        { key: 'issued-documents', label: 'Issued', route: '/documents/issued' },
        { key: 'document-templates', label: 'Templates', route: '/documents/templates' },
      ],
    },
    { key: 'invoices', label: 'Invoices', route: '/invoices' },
    {
      key: 'payroll',
      label: 'Payroll',
      route: '/payroll',
      subLinks: [
        { key: 'payroll-report', label: 'Payroll Report', route: '/payroll/payroll-report' },
        { key: 'pay-rates', label: 'Pay Rates', route: '/payroll/pay-rates' },
        { key: 'assign-rates', label: 'Assign Rates', route: '/payroll/assign-rates' },
      ],
    },
    { key: 'log-work', label: 'Log Work', route: '/log-work' },
  ],
};

export const ownerMobileRouteMap: IRouteMap = {
  layout: OwnerMobileLayout,
  routes: [
    {
      key: 'scheduling',
      component: React.lazy(() => import('pages/private/ownerMobile/Schedule')),
      path: '/',
    },
    {
      key: 'athletes',
      component: React.lazy(() => import('pages/private/ownerMobile/Athletes')),
      path: '/athletes',
    },
    {
      key: 'athlete-detail',
      component: React.lazy(() => import('pages/private/ownerMobile/AthleteDetail')),
      path: '/athletes/:userId/:activeTab?',
    },
    {
      key: 'retail',
      component: React.lazy(() => import('pages/private/ownerMobile/Retail')),
      path: '/retail',
    },
    {
      key: 'fees-and-misc',
      component: React.lazy(() => import('pages/private/ownerMobile/MiscFees')),
      path: '/fees-and-misc',
    },
    {
      key: 'account-details',
      component: React.lazy(() => import('pages/private/ownerMobile/AccountDetails')),
      path: '/account-details',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    {
      key: 'scheduling',
      Icon: IconCalendar,
      label: 'Scheduling',
      route: '/',
    },
    {
      key: 'athletes',
      Icon: IconUserCircle,
      label: 'Athletes',
      route: '/athletes',
    },
    {
      key: 'retail',
      Icon: IconArchive,
      label: 'Retail',
      route: '/retail',
    },
    {
      key: 'fees-and-misc',
      Icon: IconFileInvoice,
      label: 'Misc Fees',
      route: '/fees-and-misc',
    },
    {
      key: 'account-details',
      Icon: IconSettings,
      label: 'Settings',
      route: '/account-details',
    },
  ],
};

const flowsRouteMap: IRouteMap = {
  layout: ownerRouteMap.layout,
  routes: [
    {
      key: 'flows',
      component: React.lazy(() => import('pages/private/owner/Flows')),
      path: 'flows',
    },
    {
      key: 'flow-builder',
      component: React.lazy(() => import('pages/private/owner/FlowBuilder')),
      path: 'flows/builder',
    },
    {
      key: 'flow-builder-edit',
      component: React.lazy(() => import('pages/private/owner/FlowBuilder')),
      path: '/flows/builder/:flowId',
    },
    {
      key: 'flow-templates',
      component: React.lazy(() => import('pages/private/owner/NotificationMessageTemplates')),
      path: '/flows/templates',
    },
  ],
  navLinks: [
    {
      key: 'flows',
      label: 'Flows',
      route: '/flows',
    },
  ],
};

const customConnectOwnerRouteMap: IRouteMap = {
  layout: ownerRouteMap.layout,
  routes: [
    ...ownerRouteMap.routes,
    {
      key: 'stripe-dashboard',
      component: React.lazy(() => import('pages/private/owner/StripeDashboard')),
      path: '/stripe-dashboard',
    },
  ],
  navLinks: [
    ...ownerRouteMap.navLinks,
    {
      key: 'stripe-dashboard',
      label: 'Stripe Dashboard',
      route: '/stripe-dashboard',
    },
  ],
};

const customConnectOwnerRouteMapWithFlows: IRouteMap = {
  layout: ownerRouteMap.layout,
  routes: [...customConnectOwnerRouteMap.routes, ...flowsRouteMap.routes],
  navLinks: [...customConnectOwnerRouteMap.navLinks, ...flowsRouteMap.navLinks],
};

export const ownerRouteMapWithFlows: IRouteMap = {
  layout: ownerRouteMap.layout,
  routes: [...ownerRouteMap.routes, ...flowsRouteMap.routes],
  navLinks: [...ownerRouteMap.navLinks, ...flowsRouteMap.navLinks],
};

export const onboardingRouteMap: IRouteMap = {
  layout: PrivateLayout,
  routes: [
    {
      key: 'onboarding',
      component: React.lazy(() => import('pages/private/owner/OnboardingPage')),
      path: '/',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [
    {
      key: 'onboarding',
      label: 'Onboarding',
      route: '/',
    },
  ],
};

export const loadingRouteMap: IRouteMap = {
  layout: PrivateLayout,
  routes: [
    {
      key: 'loading',
      component: Loading,
      path: '*',
    },
  ],
  navLinks: [],
};

export const loadingMobileRouteMap: IRouteMap = {
  layout: Loading,
  routes: [
    {
      key: 'loading',
      component: Loading,
      path: '*',
    },
  ],
  navLinks: [],
};

export const getOwnerRouteMap = (
  gymHook:
    | UseQueryResult<{
        data: GymRetrieve;
      }>
    | undefined,
  flowsFlag: boolean | undefined,
  isDesktop: boolean,
): IRouteMap => {
  if (gymHook?.isSuccess && gymHook?.data.data.stripeStatus) {
    if (gymHook.data.data.stripeStatus === 'Onboard') {
      return onboardingRouteMap;
    }
    if (!isDesktop) {
      return ownerMobileRouteMap;
    }
    if (gymHook.data.data.stripeAccountType === 'custom') {
      if (flowsFlag != undefined && flowsFlag) {
        return customConnectOwnerRouteMapWithFlows;
      }
      return customConnectOwnerRouteMap;
    }
    if (flowsFlag != undefined && flowsFlag) {
      return ownerRouteMapWithFlows;
    }
    return ownerRouteMap;
  }
  return isDesktop ? loadingRouteMap : loadingMobileRouteMap;
};
